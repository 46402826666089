.custom-react-paginate-wrapper {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 5px;
  margin: 0;
  padding: 0;

  li a {
    border-radius: 7px;
    padding: 0.4rem 0.8rem;
    border: $primary 1px solid;
    cursor: pointer;
    font-weight: 600;
  }

  li a:hover {
    border-color: gray;
    background-color: #EAECEE;
  }
  
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  
  li.active a {
    background-color: $primary;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }

  li.active a:hover {
    background-color: $primary;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  
  li.disabled a {
    color: grey;
    border-color: gray;
  }

  li.disabled a:hover {
    color: grey;
    border-color: gray;
    background-color: transparent;
  }
  
  li.disable,
  
  li.disabled a {
    cursor: default;
  }
}
